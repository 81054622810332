import { Link } from 'react-router-dom';
import { Navigation } from './navigation';
import { SupportUser } from './support-user';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { theme } from '@lawcpd/learner/shared/ui'

/**Assets */
import styled from 'styled-components';

const StyledSupport = styled.div`
  background-color: ${theme.greyLightest};
  box-sizing: border-box;

  > header {
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 1;
    width: 100%;

    > .logo {
      position: relative;

      img.desktop {
        width: 18rem ;
        padding: 0.9rem 0.5rem 0.5rem 0.9rem;
      }

      img.mobile {
        display: none;
      }

      span {
        color: #bbb;
        font-size: 1rem;
        left: 9.6rem;
        position: absolute;
        top: 4.4rem;
      }
    }

    > .help {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      flex-basis: 5rem;
      font-size: 2.4rem;
      justify-content: center;
      padding: 0 0.8rem 0 1.2rem;

      a {
        background-color: ${theme.blueDarkest};
        border-radius: 3rem;
        color: white;
        display: block;
        height: 3rem;
        line-height: 2.4rem;
        text-align: center;
        text-decoration: none;
        width: 3rem;
      }

      span {
        padding-top: 0.1rem;
      }
    }
  }

  > main {
    padding: 5rem 0 5.9rem;
  }

  > nav {
    background-color: white;
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    position: fixed;
    z-index: 1;
    width: 100%
  }

  @media only screen and ${theme.largeDevice} {
    min-height: 99vh;

    > header {
      box-shadow: ${theme.boxShadow};
      grid-gap: ${theme.gap};
      padding-left: ${theme.gap};
      padding-right: ${theme.gap};

      > .help {
        display: none;
      }

      > .logo {
        flex-basis: ${theme.gutterDouble};
        flex-shrink: 0;
      }
    }

    > main {
      padding: 10.4rem ${theme.gap} 7.2rem;
    }

    > nav {
      box-shadow: ${theme.navBoxShadow};
      flex-shrink: 0;
      padding-left: ${theme.gap};
      padding-right: ${theme.gap};
    }
  }

  @media only screen and ${theme.extraLargeDevice} {
    align-items: stretch;
    box-shadow: ${theme.boxShadow};
    box-sizing: border-box;
    display: flex;
    min-height: 99vh;
    padding: 0;

    &:before {
      background-color: white;
      box-shadow: ${theme.boxShadow};
      content: '';
      display: block;
      flex-shrink: 0;
      width: 21.6rem;
    }

    > header {
      padding-left: 0;
      padding-right: ${theme.gutter};
      width: 192rem;
    }

    > main {
      flex-grow: 1;
      padding: 10.4rem ${theme.gutter} ${theme.gap} ${theme.gap};
    }

    > nav {
      bottom: auto;
      box-shadow: none;
      left: auto;
      padding: 4rem 1.2rem 1.2rem;
      top: 8rem;
      width: 21.6rem;
    }
  }

  @media only screen and ${theme.notLargeDevice} {
    > nav {
      box-shadow: ${theme.navBoxShadow};
    }

    > header {
      z-index: 100;

      > .logo {
        flex-basis: 5rem;
        img.desktop {
          display: none;
        }

        img.mobile {
          display: block;
          width: 3.9rem;
          height: 3.8rem;
          margin-left: 0.8rem;
        }

        span {
          display: none;
        }
      }
    }
  }
`;

export const Support = (props) => {
  const { help } = useEnvironment();
  return (
    <StyledSupport>
      <header>
        <div className="logo"><Link to="/"><img className="desktop" src="../assets/lawcpd_logo.svg"/><img className="mobile" src="../assets/lawcpd_icon.svg" /></Link></div>
        <SupportUser />
        <div className="help"><a href={help} target="_blank" title="Need Help? Click here"><span>?</span></a></div>
      </header>
      <main>{props.children}</main>
      <nav><Navigation /></nav>
    </StyledSupport>
  )
}

export default Support;
